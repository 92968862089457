const $ = jQuery;

export default class NavBarScroll {
    constructor(options = null) {}

    init() {
        this.__navbarScroll();
    }
    __navbarScroll() {
        $(document).ready(function () {
            let navbar = document.getElementById("navigation");
            let navbar_extra_content = document.querySelector(
                ".navigation_extra_content"
            );
            let logo = document.getElementById("logo");
            let navigation = document.getElementById("navigation");
            let menu_items = document.querySelectorAll(".primary-menu");
            let contentOffset = navigation.offsetTop;
            let menuPage = document.getElementById("menuPage");
            let placeholderNav = document.getElementById(
                "placeholder_navigation"
            );
            let primaryMenuContainer = document.querySelector(
                ".menu-primary-container"
            );
            let mobileMenuButtonContainer = document.querySelector(
                ".mobile_menu_button_container"
            );
            let $navSubMenu = $(".sub-menu");

            window.addEventListener(
                "scroll",
                function () {
                    if (window.scrollY > 88) {
                        navbar.classList.add("fixed-navbar");
                        menuPage.classList.add("moveMenuUp");
                        if ($(window).width() > 565) {
                            navbar_extra_content.classList.add("disappear");
                            primaryMenuContainer.classList.add(
                                "tighten-menu-items"
                            );
                            mobileMenuButtonContainer.classList.add(
                                "tighten-menu-buttons"
                            );
                            $navSubMenu.addClass("tighten-sub-menu");
                        }
                        logo.classList.add("reduce-logo-padding");
                        menu_items.forEach((item) =>
                            item.classList.add("make-text-white")
                        );
                        placeholderNav.classList.add("showItem");
                    } else {
                        navbar.classList.remove("fixed-navbar");
                        menuPage.classList.remove("moveMenuUp");
                        logo.classList.remove("reduce-logo-padding");
                        if ($(window).width() > 565) {
                            navbar_extra_content.classList.remove("disappear");
                            primaryMenuContainer.classList.remove(
                                "tighten-menu-items"
                            );
                            mobileMenuButtonContainer.classList.remove(
                                "tighten-menu-buttons"
                            );
                            $navSubMenu.removeClass("tighten-sub-menu");
                        }
                        menu_items.forEach((item) =>
                            item.classList.remove("make-text-white")
                        );
                        placeholderNav.classList.remove("showItem");
                    }
                },
                { passive: true }
            );
        });
    }
}
