const $ = jQuery;

export default class Menu {
    constructor(options = null) {}

    init() {
        this.__menu();
    }
    __menu() {
        $("body").on("click", ".burger", function (event) {
            // $('#menuPage').toggleClass('mobileOpen');
            // $('#page').toggleClass('mobileOpen');
            document.body.classList.toggle("menu-open");

            $(this).toggleClass("active");

            if ($(this).hasClass("active")) {
                // $('#menuPage').removeClass('hidden');

                $("#menuPage").trigger("showItem");
                // $('#menuPage').slideDown();

                $("#menuPage").slideDown(500, function () {
                    // Fade in the child elements
                    $("#menuPage").css("display", "block");
                });
            } else {
                // $('#menuPage').addClass('hidden');
                $("#menuPage").trigger("hideItem");

                $("#menuPage").slideUp(500);
                // $('#menuPage').css('display','flex')
            }
        });

        //menu click events

        $("body").on("click", ".preventDefault", function (event) {
            event.preventDefault();
        });

        $("body").on("click", ".heading h3", function (event) {
            if ($(window).width() < 991) {
                event.preventDefault();
            }
            $(this).parent().next(".sub-menu.mobile").slideToggle();
        });

        $("body").on("click", "a.menu-item-has-children", function (event) {
            if ($(window).width() < 991) {
                event.preventDefault();
            }

            var subMenu = $(this).next(".sub-menu.mobile");
            if (subMenu.children().length > 1) {
                subMenu.slideToggle();
            } else {
                subMenu.slideToggle(function () {
                    subMenu.find(".submenuWrapper .sub-menu").slideToggle();
                });
            }
        });

        //mobile menu click to open
        if ($(window).width() < 991) {
            $("a.menu-item-has-children").next(".sub-menu").addClass("mobile");
            $(".heading h3").parent().next(".sub-menu").addClass("mobile");

            $(".heading h3 a").addClass("preventDefault");
        }

        //close menu on resize
        $(window).on("resize", function () {
            var win = $(this); //this = window

            // $(".sub-menu").removeAttr("style");
            // $("#menu-primary").removeAttr("style");
            // $(".appendedMenu").removeAttr("style");
            // $(".burger").removeClass("active");
            // $("#menuPage").removeAttr("style");

            if (win.width() > 991) {
                // $('#menuPage').trigger('show')
                $("#menuPage").removeAttr("style");
                $(".sub-menu").removeAttr("style");
                $("#menu-primary").removeAttr("style");
                $(".appendedMenu").removeAttr("style");
                $(".burger").removeClass("active");

                $("body").removeClass("ios-noscroll");

                $("a.menu-item-has-children")
                    .next(".sub-menu")
                    .removeClass("mobile");
                $(".heading h3")
                    .parent()
                    .next(".sub-menu")
                    .removeClass("mobile");

                $(".heading h3 a").removeClass("preventDefault");
            } else {
                $("a.menu-item-has-children")
                    .next(".sub-menu")
                    .addClass("mobile");
                $(".heading h3").parent().next(".sub-menu").addClass("mobile");

                $(".heading h3 a").addClass("preventDefault");
            }
        });
    }
}
